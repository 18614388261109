/* eslint-disable no-console */
/* global ga fbq */

const getFbqEventName = (eventAction = '') => {
  switch (eventAction) {
    case 'Inquiry Submission - Flawless':
      return '<Flawless_Submission>';
    case 'Inquiry Submission - Private Sales':
      return '<Private_Sales_Inquiry_Submission>';
    case 'Inquiry Submission - Perpetual':
      return '<Perpetual_Inquiry_Submission>';
    case 'Inquire - Flawless':
      return '<Flawless_Inquire>';
    case 'Inquire - Private Sales':
      return '<Private_Sales_Inquire>';
    case 'Inquire - Perpetual Store':
      return '<Perpetual_Inquire>';
    case 'Signed Up':
      return '<Account_Creation>';
    case 'Favorite Lot':
      return '<Favorite_Lots>';
    case 'Follow Maker':
      return '<Follow_Maker>';
    case 'Logged In':
      return '<Logged_In>';
    case 'Submitted Consignment':
      return '<Consignment_Submission>';
    case 'newsletter subscribe':
      return '<Email_Newsletter_Submission>';
    default:
      return '';
  }
};

function sendAnalytics({ eventCategory, eventAction, eventLabel, eventValue = 0 }) {

  if (typeof gtag == 'function') { 
  //console.log("Event Action", eventAction, eventCategory, eventLabel, eventValue);
      gtag('event', eventAction, { eventCategory, eventLabel, eventValue });
  }
  if (typeof fbq !== 'undefined' && getFbqEventName(eventAction).length > 0) {
    const contentLabel = getFbqEventName(eventAction) === '<Account_Creation>' ? 'N/A' : eventLabel;
    fbq('trackCustom', getFbqEventName(eventAction), {
      content_category: eventCategory,
      content_name: eventAction,
      content_label: contentLabel
    });
  }
}

export default sendAnalytics;
